import React, {
  useCallback, memo, useState, useMemo, useEffect
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import styled from 'styled-components';
import uuidv4 from 'uuid/v4';
import queryString from 'query-string';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useTranslation, Trans } from 'react-i18next';
import useReactRouter from 'use-react-router';
import Loading from 'react-loading-animation';
import { withSnackbar } from 'notistack';
import isEmpty from 'lodash.isempty';
import trim from 'lodash.trim';
import TextField from '@material-ui/core/TextField';

/* -------------------------------------------------------------------------- */
/*                            Customize Components                            */
/* -------------------------------------------------------------------------- */
import Button from 'components/MuiComponents/RoundedButton';
import SeparationLine from 'components/SeparationLine/SeparationLine';
import { MailOutlineIcon, KeyboardArrowRightIcon } from 'components/StyledIcons/StyledIcons';

/* -------------------------------------------------------------------------- */
/*                          redux reducers / actions                          */
/* -------------------------------------------------------------------------- */
import {
  loadPreservedState as _loadPreservedState,
  login as _performLogin,
} from 'redux/modules/auth';

import {
  storeBookingStateInCloud as _storeBookingStateInCloud,
  magicLink as _loginWithMagicLinkMethod,
  clearMagicLinkResult as _clearMagicLinkResult,
} from 'redux/modules/magiclink';

import {
  changeLoginForm as _changeLoginForm,
  TOGGLE_LOGIN_VIA,
} from 'redux/modules/dialogs';

import {
  setSkipLoginBooking as _setSkipLoginBooking,
} from 'redux/modules/booking';

import {
  emailTest,
  nameTest,
} from 'utils/regexTest';

import { sendGtagEvent, sendEvent } from 'utils/gtag';
import config from 'config';
import LogoSVG from 'assets/ownrides-logo.svg';

const cdnPrefix = `${config.cdnUrl}/misc`;
const FbIcon = `${cdnPrefix}/icons/icon-facebook.png`;
const IconSuccessful = `${cdnPrefix}/icons/icon-successful.svg`;

const SkipLoginWrap = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  margin-bottom: 1rem;
  border-top: 1px solid #efefef;
`;

const SwitchFormWrap = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
`;

const TextFieldFirstName = styled(TextField)`
  &&& {
    margin-right: 1rem;
    flex: 1;
  }
`;

const TextFieldLastName = styled(TextField)`
  &&& {
    flex: 1;
  }
`;

const TextFieldEmail = styled(TextField)`
  &&& {
  }
`;

const OrSeparator = styled.div`
  display: flex;
  width: 100%;
  margin: 1rem 0 0;
  color: #555555;
  text-shadow: 0 1px 0 rgba(255,255,255,0.4);
`;

const Line = styled.div`
  flex: 1;
  position: relative;

  &:before {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 0px;
    right: 0px;
    height: 1px;
    background-color: #5F5F5F;
    box-shadow: 0 1px 0 rgba(255,255,255,0.2);
  }
`;

const LeftLineSeparator = styled(Line)`
  margin-right: 20px;
`;

const RightLineSeparator = styled(Line)`
  margin-left: 20px;
`;

const BrandingWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  margin-bottom: 1rem;
`;

const Content = styled.span`
  margin-right: 0.5rem;
  flex: 1;
  align-self: center;
  font-size: 0.875rem;
  color: ${theme => theme.greyLight75};
  font-family: ${theme => theme.fontFamilyRaleWay};
`;

const LogoImage = styled.img`
  height: 36px;
`;

const FacebookLogo = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
`;

const Flex = styled.div`
  display: flex;
`;

const MagicLinkForm = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

const SendToEmail = styled.h4`
  font-weight: 400;
`;

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const PleaseWait = styled.div`
  flex: 1 0 0;
  text-align: left;
`;

const WarningWrap = styled.ul`
  align-items: center;
  margin: 1rem 0 1rem;
  color: #c5262a;
  border: 1px solid #c5262a;
  border-left: 4px solid #c5262a;
  border-radius: 4px;
  padding: 0.5rem;
  list-style: none;
`;

const StyledLogin = styled.div`
  display: inline-block;
  margin: 0 16px -3px;
  width: 20px;
  height: 20p;
`;

const DialogTitle = styled.h3`
  margin: 1rem 0rem;
  text-align: center;
`;

const switchFormButtonStyle = {
  root: {
    display: 'flex',
    alignItems: 'center',
    background: '#ffffff',
    color: '#595959',
    boxShadow: 'none',
    border: 'none',
    padding: '8px 12px',
    borderRadius: '4px',
    '&:active, &:hover': {
      backgroundColor: '#efefef',
    },
  }
};

const FacebookLoginButton = memo(({
  /* eslint-disable-next-line react/prop-types */
  renderProps, id, currentLoginForm, t, preservedBookingState
}) => {
  const [facebookLoginClicked, setFacebookLoginClicked] = useState(false);

  const buttonStyle = {
    root: {
      background: '#3b5898',
      color: 'white',
      boxShadow: 'none',
      '&:active, &:hover': {
        backgroundColor: '#304980',
      },
    }
  };

  const onFBButtonClick = useCallback(
    e => {
      e.stopPropagation();
      sendGtagEvent('Clicked on Facebook Button', 'Interaction');
      setFacebookLoginClicked(true);
      preservedBookingState({ id });
      setTimeout(() => { renderProps.onClick(e); }, 1000);
    },
    [id, renderProps],
  );

  return (
    <Button
      variant="contained"
      overwritestyles={buttonStyle}
      onClick={onFBButtonClick}
      fullWidth
    >
      {
        facebookLoginClicked
          ? <Loading style={{ margin: '0 0.5rem 0 0', width: '20px', height: '20px' }} />
          : <FacebookLogo src={FbIcon} alt="Login with Facebook" />
      }
      <div>
        <span>{t(`AUTH_${currentLoginForm.toUpperCase()}_WITH_FACEBOOK`)}</span>
      </div>
    </Button>
  );
});

const FacebookLoginButtonController = memo(({
  /* eslint-disable react/prop-types */
  uuid,
  setUuid,
  mobileDetect,
  onFaceBookLoginCb,
  currentLoginForm,
  preservedBookingState,
  t,
  /* eslint-enable react/prop-types */
}) => {
  if (!uuid) setUuid(uuidv4());

  const isMobile = mobileDetect.isMobile || mobileDetect.isTablet;
  const renderPropsFunction = useCallback(renderProps => (
    <FacebookLoginButton
      renderProps={renderProps}
      currentLoginForm={currentLoginForm}
      id={uuid}
      preservedBookingState={preservedBookingState}

      t={t}
    />
  ), [uuid, currentLoginForm]);

  return (
    <FacebookLogin
      appId="1832315833691859"
      fields="name,email,picture"
      callback={onFaceBookLoginCb}
      version="3.2"
      cookie
      isMobile={isMobile}
      render={renderPropsFunction} // onClick is bind within this component
      redirectUri={typeof window !== 'undefined'
        ? `${window.location.origin}/callback/facebook`
        : 'https://www.ownrides.com/callback/facebook'
      }
      state={typeof window !== 'undefined'
        ? `{"type":"facebookdirect","redirectTo":"${window.location.pathname}${window.location.search}","uuid":"${uuid}"}`
        : `{"type":"facebookdirect","redirectTo":"https://www.ownrides.com/","uuid":"${uuid}"}`
      }
    />
  );
});

const Branding = memo(() => (
  <BrandingWrap>
    <picture>
      <source srcSet={LogoSVG} />
      <LogoImage src={LogoSVG} alt="OWNRIDES Logo" />
    </picture>
  </BrandingWrap>
));

const AuthError = memo(({
  /* eslint-disable-next-line react/prop-types */
  auth, t, formError
}) => {
  const {
    code: authErrorCode,
    message: authErrorMessage,
  } = auth || {};

  return (authErrorCode || !isEmpty(formError)) ? (
    <WarningWrap>
      { formError.map(e => <li>{e}</li>) }
      {
        authErrorCode === 403
          ? <li>{ t('AUTH_LOGIN_TOKEN_EXPIRED') }</li>
          : <li>{ authErrorMessage }</li>
      }
    </WarningWrap>
  ) : null;
});

const SignupForm = memo(({
  /* eslint-disable react/prop-types */
  t,
  changeLoginForm,
  firstName,
  lastName,
  firstNameError,
  lastNameError,
  emailError,
  setFirstName,
  setFirstNameError,
  setLastName,
  setLastNameError,
  setEmail,
  setEmailError,
  email,
  renderActionButton,
  setFormError,
  /* eslint-enable react/prop-types */
}) => (
  <>
    <Flex>
      <TextFieldFirstName
        required
        id="outlined-first-name"
        label="First Name"
        value={firstName}
        error={!!firstNameError}
        helperText={firstNameError}
        onChange={e => {
          setFirstName(e.target.value);

          const value = e.target.value; // eslint-disable-line prefer-destructuring

          if (!trim(value)) setFirstNameError('First name should not be empty');
          else if (nameTest.test(value)) setFirstNameError('No special charactor');
          else setFirstNameError('');

          setFirstName(value);
        }}
        margin="normal"
        variant="outlined"
      />
      <TextFieldLastName
        required
        id="outlined-last-name"
        label="Last Name"
        value={lastName}
        error={!!lastNameError}
        helperText={lastNameError}
        onChange={e => {
          const value = e.target.value; // eslint-disable-line prefer-destructuring

          if (!trim(value)) setLastNameError('Last name should not be empty');
          else if (nameTest.test(value)) setLastNameError('No special charactor');
          else setLastNameError('');

          setLastName(value);
        }}
        margin="normal"
        variant="outlined"
      />
    </Flex>
    <TextFieldEmail
      required
      id="outlined-email-input"
      label="Your email"
      type="email"
      name="email"
      autoComplete="email"
      margin="normal"
      variant="outlined"
      value={email}
      error={!!emailError}
      helperText={emailError}
      onChange={e => {
        const value = e.target.value; // eslint-disable-line prefer-destructuring

        if (!trim(value)) setEmailError('Email should not be empty');
        else if (!emailTest.test(value)) setEmailError('Email is invalid');
        else setEmailError('');

        setEmail(e.target.value);
      }}
      fullWidth
    />
    { renderActionButton() }
    <SwitchFormWrap>
      <Content>{t('AUTH_WHETHER_HAS_ACCOUNT')}</Content>
      <Button
        variant="contained"
        onClick={() => {
          changeLoginForm('login');
          setFormError([]);
        }}
        overwritestyles={switchFormButtonStyle}
      >
        {t('AUTH_LOGIN')}
      </Button>
    </SwitchFormWrap>
  </>
));

const LoginForm = memo(({
  /* eslint-disable react/prop-types */
  t,
  changeLoginForm,
  email,
  setEmail,
  renderActionButton,
  setFormError,
  emailError,
  setEmailError
  /* eslint-enable react/prop-types */
}) => (
  <>
    <TextField
      required
      id="outlined-email-input"
      label="Your email"
      type="email"
      name="email"
      autoComplete="email"
      margin="normal"
      variant="outlined"
      value={email}
      error={!!emailError}
      helperText={emailError}
      onChange={e => {
        const value = e.target.value; // eslint-disable-line prefer-destructuring

        if (!trim(value)) setEmailError('Email should not be empty');
        else if (!emailTest.test(value)) setEmailError('Email is invalid');
        else setEmailError('');

        setEmail(e.target.value);
      }}
      fullWidth
    />
    { renderActionButton() }
    <SwitchFormWrap>
      <Content>Don’t have an account?</Content>
      <Button
        variant="contained"
        onClick={() => {
          changeLoginForm('signup');
          setFormError([]);
        }}
        overwritestyles={switchFormButtonStyle}
      >
        {t('AUTH_SIGNUP')}
      </Button>
    </SwitchFormWrap>
  </>
));

const LoginSignupForm = memo(({
  /* eslint-disable react/prop-types */
  auth,
  t,
  changeLoginForm,
  currentLoginForm,
  renderFacebookLoginButtonController,
  loginWithMagicLinkMethod,
  setFormError,
  formError,
  magicLinkLoading,
  onSkipLoginClick,
  dialogToggleVia
  /* eslint-enable react/prop-types */
}) => {
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  const actionButtonStyle = useMemo(() => ({
    root: {
      margin: '1rem 0',
    }
  }), []);

  const mailIconStyle = useMemo(() => ({
    root: {
      marginRight: '0.5rem'
    }
  }), []);

  const onEmailAuthenticationClick = useCallback(e => {
    e.preventDefault();

    loginWithMagicLinkMethod({
      email,
      domain: window?.location?.origin,
      firstName,
      lastName
    });
  }, [email, firstName, lastName, currentLoginForm]);

  const renderActionButton = () => (
    <Button
      variant="contained"
      color="primary"
      fullWidth
      type="submit"
      overwritestyles={actionButtonStyle}
    >
      {
        magicLinkLoading
          ? <Loading style={{ margin: '0 0.5rem 0 0', width: '20px', height: '20px' }} />
          : <MailOutlineIcon overwritestyles={mailIconStyle} />
      }
      <div>
        <span>
          { t(`${currentLoginForm.toUpperCase()}_WITH_EMAIL`) }
        </span>
      </div>
    </Button>
  );

  const arrowRightStyle = useMemo(() => ({
    root: {
      marginLeft: '0.25rem',
      color: '#818283',
      fontSize: '1rem'
    }
  }), []);

  const skipButtonStyle = useMemo(() => ({
    root: {
      ...switchFormButtonStyle.root,
      fontWeight: '400',
      color: '#818283',
    }
  }));

  return (
    <div>
      <AuthError
        auth={auth}
        t={t}
        formError={formError}
      />

      <div style={{ display: 'none' }}>
      { renderFacebookLoginButtonController() }
        <OrSeparator key="or-seperator">
          <LeftLineSeparator />
          <div>or</div>
          <RightLineSeparator />
        </OrSeparator>
      </div>

      <form onSubmit={onEmailAuthenticationClick}>
        {
          currentLoginForm === 'login' ? (
            <LoginForm
              t={t}
              changeLoginForm={changeLoginForm}
              setEmail={setEmail}
              email={email}
              renderActionButton={renderActionButton}
              setFormError={setFormError}
              setEmailError={setEmailError}
              emailError={emailError}
            />
          ) : (
            <SignupForm
              t={t}
              changeLoginForm={changeLoginForm}
              firstName={firstName}
              lastName={lastName}
              setFirstName={setFirstName}
              setLastName={setLastName}
              setEmail={setEmail}
              email={email}
              renderActionButton={renderActionButton}
              setFormError={setFormError}
              setFirstNameError={setFirstNameError}
              setLastNameError={setLastNameError}
              setEmailError={setEmailError}
              firstNameError={firstNameError}
              lastNameError={lastNameError}
              emailError={emailError}
            />
          )
        }
      </form>
      {
        dialogToggleVia === TOGGLE_LOGIN_VIA.bookingConfirm && currentLoginForm === 'signup' ? (
          <SkipLoginWrap>
            <Button
              variant="contained"
              overwritestyles={skipButtonStyle}
              onClick={onSkipLoginClick}
              data-cy="cy-skip-login"
            >
              <span>{t('SKIP_LOGIN')}</span>
              <KeyboardArrowRightIcon overwritestyles={arrowRightStyle} />
            </Button>
          </SkipLoginWrap>
        ) : null
      }
    </div>
  );
});

const MagicLinkSentForm = memo(({
  /* eslint-disable-next-line react/prop-types */
  email, t,
}) => (
  <div>
    <Branding />
    <MagicLinkForm>
      <SendToEmail>
        <Trans i18nKey="AUTH_LINK_SENT_TO_EMAIL">
          We just emailed a confirmation link to <b>{{ email }}</b>. Click the link, and you’ll be signed in.
        </Trans>
      </SendToEmail>

      <SeparationLine />

      <small>
        {t('AUTH_IF_NO_MAIL_RECIEVED')} <b>{t('AUTH_SPAM_FOLDER')}</b>.
      </small>

      <small>
        Having trouble signing in? Please &nbsp;
        <a
          href="https://www.messenger.com/t/680688862090703"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => { sendGtagEvent('Message Us', 'interaction', 'Footer'); }}
        >
          contact us
        </a>
      </small>
    </MagicLinkForm>
  </div>
));

const AuthFunction = props => {
  const {
    mobileDetect,
    loadPreservedState,
    enqueueSnackbar,
    performLogin,
    presetRedirectPath,
    auth,
    currentLoginForm,
    changeLoginForm,
    handleDialogClose,
    loginWithMagicLinkMethod,
    magiclink,
    booking,
    customize,
    planner,
    storeBookingStateInCloud,
    clearMagicLinkResult,
    dialogToggleVia,
    setSkipLoginBooking
  } = props;

  const [uuid, setUuid] = useState();
  const [formError, setFormError] = useState('');
  const { location, history } = useReactRouter();
  const { t } = useTranslation(['auth', 'new-version']);
  const search = location?.search;
  const pathname = location?.pathname;
  const { errorCode, loading } = auth || {};
  const accessToken = auth?.user?.owt?.access_token; // eslint-disable-line camelcase
  const fbProfile = auth?.user?.facebook?.profile;
  const fbName = auth?.user?.facebook?.profile?.displayName;
  const {
    email: magiclinkEmail,
    result: magicLinkResult,
    loading: magicLinkLoading,
  } = magiclink;

  const continueButtonStyle = useMemo(() => ({
    root: {
      marginBottom: '1rem',
    }
  }), []);

  // const starIconStyle = useMemo(() => ({
  //   root: {
  //     fontSize: '3rem',
  //     color: '#00b33b',
  //   }
  // }));

  const onSkipLoginClick = useCallback(
    e => {
      e.stopPropagation();
      sendEvent({ action: 'Click Skip Login', category: 'engagement', label: pathname });
      setSkipLoginBooking(true);
      handleDialogClose();
    },
    [pathname],
  );

  const getUUIDFromFacebookState = useCallback(facebookState => {
    try {
      const { uuid: _uuid = '' } = facebookState ? JSON.parse(facebookState) : {};

      return _uuid;
    } catch (e) {
      console.log(e);
    }

    return '';
  }, []);

  const getRedirectPathname = useCallback((preset, _pathname, _search) => {
    if (preset) return { redirect: presetRedirectPath };

    const isRootPath = _pathname === '/';
    const prefixPath = isRootPath ? '' : _pathname;

    return _pathname
      ? { redirect: `${prefixPath}/${_search}` }
      : { redirect: '/' };
  }, []);

  const preservedBookingState = useCallback(({ id }) => {
    const toStore = {};

    if (booking) toStore.booking = booking;
    if (customize) toStore.customize = customize;
    if (planner) toStore.planner = planner;

    toStore.redirect = getRedirectPathname(presetRedirectPath, pathname, search);

    storeBookingStateInCloud({ data: toStore, id });
  }, [presetRedirectPath, pathname, search, booking, customize, planner]);

  const onFaceBookLoginCb = useCallback(loginData => {
    performLogin('facebook', loginData, false)
      .then(() => {
        const { state: facebookState } = queryString.parse(search);
        const uuidFromUrl = getUUIDFromFacebookState(facebookState);

        if (uuidFromUrl) loadPreservedState(uuidFromUrl);
        if (presetRedirectPath) {
          history.replace(presetRedirectPath);
          enqueueSnackbar(t('AUTH_SUCCESS_LOGIN_MESSAGE'), {
            variant: 'success',
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right'
            }
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, [search]);

  const renderFacebookLoginButtonController = () => (
    <FacebookLoginButtonController
      mobileDetect={mobileDetect}
      uuid={uuid}
      setUuid={setUuid}
      onFaceBookLoginCb={onFaceBookLoginCb}
      currentLoginForm={currentLoginForm}
      preservedBookingState={preservedBookingState}
      t={t}
    />
  );

  const onContinueButtonClick = useCallback(
    e => {
      e.stopPropagation();
      handleDialogClose();
    },
    [],
  );

  useEffect(() => {
    const {
      code,
      error,
      partial_code: partialCode,
      created_at: createdAt,
    } = magicLinkResult;
    if (code && error) {
      changeLoginForm('signup');
      setFormError([
        error || t('AUTH_USER_HAS_NO_ACCOUNT')
      ]);
      clearMagicLinkResult();
    } else if (createdAt) {
      changeLoginForm('magiclinksent');
      if (partialCode) preservedBookingState({ id: partialCode });
      clearMagicLinkResult();
    }
  }, [magicLinkResult]);

  if (accessToken && !errorCode) {
    return (
      <div key="login-successful-dialog">
        <DialogContainer>
          <picture>
            <source srcSet={IconSuccessful} />
            <img src={IconSuccessful} alt="Login Successfull" />
          </picture>
          <DialogTitle>{t('AUTH_SUCCESS_LOGIN_MESSAGE')}</DialogTitle>
        </DialogContainer>
        {
          fbProfile ? (
            <div>
              <p style={{ textAlign: 'center' }}>
                {`You are currently logged in as ${fbName}.`}
              </p>
            </div>
          ) : null
        }
        <Button
          color="primary"
          variant="contained"
          fullWidth
          overwritestyles={continueButtonStyle}
          onClick={onContinueButtonClick}
        >
          Continue
        </Button>
      </div>
    );
  }

  return (
    <div>
      {/* {
        dialogToggleVia === TOGGLE_LOGIN_VIA.bookingConfirm ? (
          <WhyWrap>
            <StarIconWrap>
              <StarsRoundedIcon overwritestyles={starIconStyle} />
            </StarIconWrap>
            <WhyLoginHeader variant="h3">
              Book and customize your trip freely
            </WhyLoginHeader>
            <WhyList>
              <WhyListItem>Checkout faster with your OWNRIDES account</WhyListItem>
              <WhyListItem>Modify your booking</WhyListItem>
              <WhyListItem>{t('new-version:NEW_ITINERARY.CANCELLATION_POLICY_DESC')}</WhyListItem>
            </WhyList>
          </WhyWrap>
        ) : null
      } */}
      {
        loading ? (
          <p className="text-center">
            <StyledLogin />
            <PleaseWait>{t('AUTH_PROCEED_PLEASE_WAIT')}</PleaseWait>
          </p>
        ) : null
      }
      {
        currentLoginForm === 'magiclinksent' ? (
          <MagicLinkSentForm
            email={magiclinkEmail}
            t={t}
          />
        ) : (
          <LoginSignupForm
            auth={auth}
            t={t}
            changeLoginForm={changeLoginForm}
            renderFacebookLoginButtonController={renderFacebookLoginButtonController}
            currentLoginForm={currentLoginForm}
            setFormError={setFormError}
            formError={formError}
            loginWithMagicLinkMethod={loginWithMagicLinkMethod}
            magicLinkLoading={magicLinkLoading}
            dialogToggleVia={dialogToggleVia}
            onSkipLoginClick={onSkipLoginClick}
          />
        )
      }
    </div>
  );
};

AuthFunction.propTypes = {
  auth: PropTypes.shape({
    errorCode: PropTypes.number,
    errorMessage: PropTypes.string,
    message: PropTypes.string,
    user: PropTypes.object
  }),
  currentLoginForm: PropTypes.string.isRequired,
  booking: PropTypes.objectOf(PropTypes.any),
  planner: PropTypes.objectOf(PropTypes.any),
  customize: PropTypes.objectOf(PropTypes.any),
  mobileDetect: PropTypes.objectOf(PropTypes.any),
  magiclink: PropTypes.objectOf(PropTypes.any),
  loadPreservedState: PropTypes.func,
  enqueueSnackbar: PropTypes.func,
  performLogin: PropTypes.func,
  presetRedirectPath: PropTypes.string,
  changeLoginForm: PropTypes.func,
  handleDialogClose: PropTypes.func,
  storeBookingStateInCloud: PropTypes.func,
  loginWithMagicLinkMethod: PropTypes.func,
  clearMagicLinkResult: PropTypes.func,
  dialogToggleVia: PropTypes.string,
  setSkipLoginBooking: PropTypes.func,
};

AuthFunction.defaultProps = {
  auth: {},
  loadPreservedState: () => {},
  enqueueSnackbar: () => {},
  performLogin: () => {},
  changeLoginForm: () => {},
  storeBookingStateInCloud: () => {},
  presetRedirectPath: '',
  mobileDetect: {},
  magiclink: {},
  booking: {},
  planner: {},
  customize: {},
  // from parent
  handleDialogClose: () => {},
  loginWithMagicLinkMethod: () => {},
  clearMagicLinkResult: () => {},
  setSkipLoginBooking: () => {},
  dialogToggleVia: '',
};

export default compose(
  connect(state => ({
    auth: state.auth,
    mobileDetect: state.mobileDetect,
    currentLoginForm: state.dialogs?.currentLoginForm,
    dialogToggleVia: state.dialogs?.via,
    magiclink: state.magiclink,
    booking: state.booking,
    planner: state.planner,
    customize: state.customize,
  }), {
    loadPreservedState: _loadPreservedState,
    performLogin: _performLogin,
    changeLoginForm: _changeLoginForm,
    storeBookingStateInCloud: _storeBookingStateInCloud,
    loginWithMagicLinkMethod: _loginWithMagicLinkMethod,
    clearMagicLinkResult: _clearMagicLinkResult,
    setSkipLoginBooking: _setSkipLoginBooking,
  }),
  withSnackbar
)(AuthFunction);
